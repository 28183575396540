import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const NewSaleDialog = ({
  onSubmit,
  setFieldValue,
  isOpen,
  setIsOpen,
  values: {
    price,
    phoneNumber,
    orderReference,
    operator,
    currency,
    simCardOrderId,
    firstName,
    lastName,
  },
}) => {
  const handleChange = ({ target: { name, value } }) => {
    setFieldValue(name, value);
  };
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>
        <h3>New Sim Card Sale № {simCardOrderId}</h3>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="First Name"
              name="firstName"
              onChange={handleChange}
              value={firstName}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="lastName"
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label="Last Name"
              value={lastName}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="phoneNumber"
              onChange={handleChange}
              value={phoneNumber}
              fullWidth
              variant="outlined"
              label="Phone Number"
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="operator"
              value={operator}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label="Operator"
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              value={price}
              name="price"
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label="Price"
            />
          </Grid>
          <Grid item sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                onChange={({ target: { value } }) =>
                  setFieldValue('currency', value)
                }
                labelId="currency-label"
                label="Currency"
                value={currency}
              >
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="ILS">ILS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: 24 }}>
        <Button variant="outlined" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={onSubmit}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSaleDialog;
