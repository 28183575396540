import React from 'react';
import { Quote } from 'views/Leads/Components/AddQuotation/Tabs';
import CloseIcon from '@mui/icons-material/Close';
import { Transition } from '../../../assets';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
const AddQuotationDialog = ({
  language,
  open,
  handleClose,
  createdLead,
  ...rest
}) => (
  <Dialog
    maxWidth="lg"
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
  >
    <AppBar position="static" style={{ marginBottom: '8px' }}>
      <Toolbar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button>
        </div>
      </Toolbar>
    </AppBar>
    <Container>
      <Quote language="en" {...createdLead} {...rest} />
    </Container>
  </Dialog>
);

export default AddQuotationDialog;
