/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ExpandedMenuItem from './components/ExpandedMenuItem';
import CustomRouterLink from './components/CustomRouterLink';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { colors } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
const useStyles = makeStyles((theme) => ({
  // icon: {
  //   color: theme.palette.icon,
  //   width: 24,
  //   height: 24,
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginRight: theme.spacing(1),
  // },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const SidebarNav = (props) => {
  const apis = [
    { apiName: 'Ofran', pathname: 'ofran' },
    { apiName: 'Top Rent A Car', pathname: 'toprentacar' },
  ];
  const { pages, className, suppliers, ...rest } = props;
  const classes = useStyles();
  const [suppliersOptions] = React.useState([...new Set(suppliers)]);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => {
        const { title, href } = page;

        return (
          <ListItem
            sx={{
              display: 'flex',
              paddingTop: 0,
              paddingBottom: 0,
            }}
            disableGutters
            key={page.title}
          >
            {title !== 'Cars pricelist' && page.title !== 'Api Config' ? (
              <Button
                sx={{
                  color: colors.blueGrey[800],
                  padding: '10px 8px',
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  letterSpacing: 0,
                  width: '100%',
                  fontWeight: 'typography.fontWeightMedium',
                  '&.active': {
                    color: 'primary.main',
                    fontWeight: 'typography.fontWeightMedium',
                    '& $icon': {
                      color: 'primary.main',
                    },
                  },
                }}
                component={CustomRouterLink}
                to={href}
              >
                <Box
                  sx={{
                    color: 'icon',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 1,
                  }}
                >
                  {page.icon}
                </Box>
                {title}
              </Button>
            ) : (
              <ExpandedMenuItem
                options={
                  page.title === 'Cars pricelist'
                    ? suppliersOptions
                    : ['Ofran', 'Top Rent A Car']
                }
                page={page}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
