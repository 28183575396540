import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import format from 'date-fns/format';
import React, { useState, useEffect } from 'react';
import TableComponent from './TableComponent';

const SeasonsTable = ({ seasons }) => {
  const [rows, setRows] = useState(seasons);
  useEffect(() => {
    setRows(seasons);
  }, [seasons]);
  return (
    <TableContainer component={TableComponent}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Season type</TableCell>
            <TableCell align="center">From</TableCell>
            <TableCell align="center">Until</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{row.seasonType}</TableCell>
                  <TableCell align="center">
                    {format(new Date(row.from), 'dd.MM.yyyy')}
                  </TableCell>
                  <TableCell align="center">
                    {format(new Date(row.until.split('T')[0]), 'dd.MM.yyyy')}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {/* <Button style={{ marginLeft: 8 }} onClick={() => handleOpenDialog('addPromoDialog', true)}>
        Add promo dates
      </Button> */}
    </TableContainer>
  );
};

export default SeasonsTable;
