import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

const DateFormatter = ({ value }) => {
  if (value) {
    const date = new Date(value).toLocaleDateString('en-GB');
    return date;
  } else return '';
};
const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const EventsTable = ({ events }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      events.map((event, id) => {
        return { ...event, id };
      })
    );
  }, [events]);

  const [columns] = useState([
    {
      name: 'supplier',
      title: 'Supplier',
      getCellValue: (row) => {
        return row.supplier.name;
      },
    },
    {
      name: 'type',
      title: 'Type',
    },
    { name: 'from', title: 'From' },
    { name: 'until', title: 'Until' },
  ]);
  const [dateColumns] = useState(['from', 'until']);

  return (
    <Paper style={{ marginTop: 32 }}>
      <Grid rows={rows} columns={columns}>
        <DateTypeProvider for={dateColumns} />
        <Table />
        <TableHeaderRow />
        <EditingState onCommitChanges={() => {}} />
        <TableEditRow />
        <TableEditColumn showEditCommand showDeleteCommand />
      </Grid>
    </Paper>
  );
};

export default EventsTable;
