import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SectionTitle from './SectionTitle';

const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) => `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`
);
const currencies = ['USD', 'EUR', 'ILS', 'CHF', 'GBP', 'HUF', 'AED'];
const LocationAndTime = ({ order, setOrder, setCountries, countries }) => {
  // useEffect(() => {
  //   console.log(countries);
  // }, [countries]);
  const handleTimeChange = e => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
  };
  return (
    <>
      <SectionTitle title={'Location and Time'} />

      <Box component='fieldset' sx={{ padding: 1, borderColor: 'rgba(0, 0, 0, 0.5)', fontFamily: 'Roboto' }}>
        <legend style={{ fontWeight: 'bold' }}>PICK UP</legend>
        <Grid container spacing={1} style={{ maxWidth: '800px' }}>
          <Grid size={{ xs: 7 }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Country</InputLabel>
              <Select
                fullWidth
                value={order.country}
                onChange={e => setOrder({ ...order, country: e.target.value })}
              >
                {countries.map(country => (
                  <MenuItem value={country}>{country.name.en}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 5 }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Currency</InputLabel>
              <Select
                fullWidth
                value={order.currencyCode}
                onChange={e => setOrder({ ...order, currencyCode: e.target.value })}
              >
                {currencies.map(currency => {
                  return <MenuItem value={currency}>{currency}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 7 }}>
            <TextField
              size='small'
              fullWidth
              label='Pick Up Location'
              name='pickUpLocation'
              value={order.pickUpLocation.LocationName}
              onChange={e =>
                setOrder({
                  ...order,
                  pickUpLocation: {
                    ...order.pickUpLocation,
                    LocationName: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid size={{ xs: 5 }}>
            <TextField
              size='small'
              fullWidth
              label='Phone'
              name='pickUpLocationPhone'
              value={order.pickUpLocation.phone}
              onChange={e =>
                setOrder({
                  ...order,
                  pickUpLocation: {
                    ...order.pickUpLocation,
                    phone: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              size='small'
              fullWidth
              label='Address'
              value={order.pickUpLocation.Address}
              onChange={e =>
                setOrder({
                  ...order,
                  pickUpLocation: {
                    ...order.pickUpLocation,
                    Address: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 7 }}>
            <DatePicker
              slotProps={{ textField: { size: 'small' } }}
              variant='inline'
              disableToolbar
              autoOk
              value={order.pickUpDate}
              format='dd/MM/yyyy'
              label='Pick Up Date'
              onChange={date => setOrder({ ...order, pickUpDate: new Date(date).toISOString() })}
              disablePast
            />
          </Grid>
          <Grid size={{ sm: 5 }}>
            <FormControl size='small' fullWidth>
              <InputLabel>Pick up time</InputLabel>
              <Select
                value={order.pickUpTime}
                items={timeSlots}
                name='pickUpTime'
                label={'Pick Up Time'}
                type='time'
                onChange={handleTimeChange}
              >
                {timeSlots.map(slot => {
                  return <MenuItem value={slot}>{slot}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        component='fieldset'
        sx={{ padding: 1, marginTop: 2, fontFamily: 'Roboto', borderColor: 'rgba(0,0,0,0.5)' }}
      >
        <legend style={{ fontWeight: 'bold' }}>DROP OFF</legend>
        <Grid container spacing={1} style={{ maxWidth: '800px' }}>
          <Grid size={{ xs: 7, lg: 7 }}>
            <TextField
              size='small'
              fullWidth
              label='Drop Off Location'
              type='location'
              value={order.dropOffLocation.LocationName}
              onChange={e =>
                setOrder({
                  ...order,
                  dropOffLocation: {
                    ...order.dropOffLocation,
                    LocationName: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid size={{ xs: 5 }}>
            <TextField
              size='small'
              fullWidth
              label='Phone'
              name='dropOffLocationPhone'
              value={order.dropOffLocation.phone}
              onChange={e =>
                setOrder({
                  ...order,
                  dropOffLocation: {
                    ...order.dropOffLocation,
                    phone: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              fullWidth
              size='small'
              label='Address'
              value={order.dropOffLocation.Address}
              onChange={e =>
                setOrder({
                  ...order,
                  dropOffLocation: {
                    ...order.dropOffLocation,
                    Address: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4, lg: 7 }}>
            <DatePicker
              slotProps={{ textField: { size: 'small' } }}
              variant='inline'
              disableToolbar
              autoOk
              value={order.dropOffDate}
              format='dd/MM/yyyy'
              label={'Drop Off Date'}
              onChange={date => setOrder({ ...order, dropOffDate: new Date(date).toISOString() })}
              disablePast
            />
          </Grid>
          <Grid size={{ sm: 4, lg: 5 }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Drop off time</InputLabel>
              <Select
                value={order.dropOffTime}
                items={timeSlots}
                label='Drop off Time'
                type='time'
                name='dropOffTime'
                onChange={handleTimeChange}
              >
                {timeSlots.map(slot => {
                  return <MenuItem value={slot}>{slot}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        {' '}
        <Grid size={{ sm: 5 }}>
          <TextField
            size='small'
            value={order.numberOfDays}
            required
            label='Number of days'
            type='number'
            onChange={e => setOrder({ ...order, numberOfDays: parseInt(e.target.value) })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LocationAndTime;
