import Paper from '@mui/material/Paper';

const TableComponent = (props) => {
  return (
    <Paper
      elevation={0}
      {...props}
      style={{ marginTop: 8, border: '1px solid #d9c8c8 ' }}
    />
  );
};

export default TableComponent;
