import { useState, useEffect, useContext } from 'react';
import AlertMessage from './Components/AlertMessage';
import {
  EditPriceListNameDialog,
  TopBar,
  CarsPriceListTable,
} from './Components';
import NewPriceListDialog from './Components/NewPriceListDialog/NewPriceListDialog';
import { CarsContext, ChangesContext } from 'sharedState/authState';
import StopSaleDialog from './Components/StopSaleDialog/StopSaleDialog';
import DeletePriceListDialog from './Components/Dialogs/DeletePriceListDialog';
import EventsSections from './Components/EventsSection/EventsSections';
import AddPromoDialog from './Components/Dialogs/AddPromoDialog';
import AddNewHolidayDialog from './Components/Dialogs/AddNewHolidayDialog';
import Paper from '@mui/material/Paper';

import {
  ViewContainer,
  ViewProgressBar,
} from '../../assets/reusableItems/items';
import {
  changePriceListVisibility,
  changePromoStatus,
  deletePriceList,
  editPriceListName,
  getUpcomingEvents,
} from 'apiRequests';

const CarsPriceList = () => {
  const [cars] = useContext(CarsContext);

  const [changes, setChanges] = useContext(ChangesContext);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  let priceLists = cars;
  const [isSaved, setIsSaved] = useState({
    status: false,
    type: 'success',
    message: '',
  });
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState({
    dialogName: '',
    isOpen: false,
  });

  const [value, setValue] = useState(0);

  const currentPriceList = priceLists[value];
  const [carsPrices, setCarsPrices] = useState(priceLists || []);
  const [isActive, setIsActive] = useState(false);
  const [isPromo, setIsPromo] = useState(false);

  const [events, setEvents] = useState({
    stopSale: [],
    holidays: [],
    seasons: [],
  });

  const handleOpenDialog = (dialogName, isOpen) => {
    setDialogOpen({ dialogName, isOpen });
  };
  useEffect(() => {
    getUpcomingEvents()
      .then(({ data }) => {
        setEvents({
          stopSale: data.stopSale,
          holidays: data.holidays,
          seasons: data.seasons,
        });
      })
      .catch((err) => console.log(err));
  }, [fetchTrigger]);

  useEffect(() => {
    if (currentPriceList) {
      setIsPromo(currentPriceList.isPromo);
    }
  }, [currentPriceList]);

  const handleEditPriceListName = async (name) => {
    try {
      await editPriceListName(priceLists[value]._id, name);
      setDialogOpen({ dialogName: '', isOpen: false });
      setIsSaved({ status: true, type: 'success', message: 'Saved' });

      setTimeout(() => {
        setChanges((prevState) => ({
          changes: !prevState.changes,
        }));
      }, 1000);
    } catch (err) {
      console.log(err);
      alert(err.response.message);
    }
  };

  useEffect(() => {
    if (carsPrices[value]) {
      setIsActive(carsPrices[value].isActive);
    }
  }, [carsPrices, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleDeletePriceList = () => {
    deletePriceList(carsPrices[value]._id)
      .then(() => {
        setIsSaved({ status: true, type: 'success', message: 'Deleted' });
        setChanges((prevState) => ({
          changes: !prevState.changes,
        }));
        alert(`The price list ${carsPrices[value].priceListName} deleted`);
      })
      .catch((err) =>
        setIsSaved({
          status: false,
          type: 'error',
          message: "Can't delete the price list",
        })
      );
  };

  const handlePromoChange = (e) => {
    const status = e.target.checked;
    setIsPromo(status);
    changePromoStatus(currentPriceList._id, status)
      .then(() =>
        setIsSaved({ status: true, type: 'success', message: 'saved' })
      )
      .catch((err) => {
        console.log(err);
        setIsSaved({
          status: true,
          type: 'error',
          message: 'Error: cant save',
        });
      });
  };

  const handleActiveChange = (e) => {
    setIsActive(e.target.checked);
    const { _id, currency, isReligious } = priceLists[value];
    changePriceListVisibility(_id, e.target.checked, currency, isReligious)
      .then((res) => {
        setIsSaved({ status: true, type: 'success', message: 'saved' });
      })
      .catch(() => {
        console.log("ERROR: can't save");
        setIsSaved({
          status: true,
          type: 'error',
          message: "ERROR: cant't save",
        });
        setTimeout(() => {
          setIsActive(carsPrices[value].isActive);
        }, 1000);
      });
  };
  // useEffect(() => {
  //   console.log('CARS PRICES', carsPrices);
  //   console.log('NAME', carsPrices[value].priceListName);
  // });
  return (
    <ViewContainer container>
      {priceLists.length > 0 && (
        <TopBar
          setDialogOpen={setDialogOpen}
          handleDeletePriceList={handleDeletePriceList}
          setChanges={setChanges}
          carsPrices={carsPrices}
          handleActiveChange={handleActiveChange}
          handleChange={handleChange}
          value={value}
          setOpen={setOpen}
          isActive={isActive}
          isPromo={isPromo}
          handlePromoChange={handlePromoChange}
        />
      )}
      <EventsSections
        setChanges={setChanges}
        priceListName={
          priceLists && priceLists[value] ? priceLists[value].priceListName : ''
        }
        promo={
          currentPriceList && currentPriceList.validityPeriod
            ? currentPriceList.validityPeriod
            : []
        }
        isPromo={isPromo}
        events={events}
        handleOpenDialog={handleOpenDialog}
      />

      <Paper>
        {carsPrices && carsPrices[value] ? (
          <CarsPriceListTable
            carsPrices={carsPrices[value].cars}
            priceListID={carsPrices[value]._id}
            setChanges={setChanges}
          />
        ) : (
          <ViewProgressBar />
        )}
      </Paper>
      <AlertMessage isSaved={isSaved} setIsSaved={setIsSaved} />
      <EditPriceListNameDialog
        currentName={
          carsPrices.length > 0 ? carsPrices[value].priceListName : ''
        }
        open={
          dialogOpen.isOpen &&
          dialogOpen.dialogName === 'EditPriceListNameDialog'
        }
        setDialogOpen={setDialogOpen}
        handleEditPriceListName={handleEditPriceListName}
      />
      <NewPriceListDialog
        open={open}
        setOpen={setOpen}
        setChanges={setChanges}
        setIsSaved={setIsSaved}
      />
      <StopSaleDialog
        open={dialogOpen.isOpen && dialogOpen.dialogName === 'StopSaleDialog'}
        setDialogOpen={setDialogOpen}
      />
      <AddPromoDialog
        setChanges={setChanges}
        priceListID={currentPriceList ? currentPriceList._id : 0}
        open={dialogOpen.isOpen && dialogOpen.dialogName === 'addPromoDialog'}
        handleOpenDialog={handleOpenDialog}
      />
      <AddNewHolidayDialog
        setFetchTrigger={setFetchTrigger}
        open={dialogOpen.dialogName === 'addNewHoliday' && dialogOpen.isOpen}
        handleOpenDialog={handleOpenDialog}
      />
      <DeletePriceListDialog
        open={dialogOpen.dialogName === 'DeletePriceList' && dialogOpen.isOpen}
        setDialogOpen={setDialogOpen}
        handleDeletePriceList={handleDeletePriceList}
      />
    </ViewContainer>
  );
};

export default CarsPriceList;
