import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: relative;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  color: white;
  left: 40%;
  top: 20%;
`;
