import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Typography,
  Grid,
  TextField,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ReservationDetails from './Components/ReservationDetails';
import ExtrasAndInsurances from './Components/ExtrasAndInsurances';
import Policies from './Components/Policies';
import NotIncluded from './Components/NotIncluded';
import OtherInformation from './Components/OtherInformation';
import LocationAndTime from './Components/LocationAndTime';
import { getAllCountries, issueVoucher, saveOrder } from 'apiRequests';
import CarDetails from './Components/CarDetails';
import VoucherRemarks from './Components/VoucherRemarks/VoucherRemarks';
import {
  ButtonWrapper,
  StyledCircularProgress,
} from './styles/AddNewOrder.styled';
import OrderSavedMessage from './Components/BottomMessages/OrderSavedMessage';
import { getOrderId, timeSlots } from './Components/helpers';
import DriverDetailsSection from './Components/DriverDetailsSection';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewOrder = ({ setOpen, open, editOrder }) => {
  const isEditMode = !!editOrder;
  const {
    sippCode,
    orderDate,
    driverDetails,
    supplier,
    carGroup,
    pickUpLocation,
    dropOffLocation,
    dropOffDate,
    dropOffTime,
    otherInformation,
    protections,
    voucher,
    supplierReservationData,
    currencyCode,
    orderId,
    pickUpTime,
    numberOfDays,
    pickUpDate,
    vehicleImageURL,
    vehicle_type,
    userExtras,
    additionalProducts,
  } = editOrder;
  const headerStamps = voucher ? voucher.stamps.headerStamps : [];

  const handleIssueVoucher = async () => {
    setIsLoading({ type: 'issuing', status: true });
    try {
      const {
        data: { filePath },
        data: { fileName },
      } = await issueVoucher(order);
      if (filePath) {
        const link = document.createElement('a');
        link.href = filePath;
        link.setAttribute('download', `Res#${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      alert('ISSUE VOUCHER ERROR');
      console.log('ISSUE VOUCHER ERROR', err);
    } finally {
      setIsLoading({ type: '', status: false });
    }
  };
  const [countries, setCountries] = useState([]);
  const [infoMessage, setInfoMessage] = useState({
    open: false,
    severity: 'success',
  });

  useEffect(() => {
    getAllCountries()
      .then(({ data }) => {
        let defaultCountry;
        if (editOrder) {
          defaultCountry = data.find((c) => c.code === editOrder.country);
          setOrder({ ...order, country: defaultCountry });
        }
        setCountries(data);
      })
      .catch((err) => console.log(new Error(err)));
  }, []);

  const [order, setOrder] = useState({
    orderId: orderId ? orderId : getOrderId(),
    sippCode: sippCode || '',
    orderDate: orderDate || new Date(),
    vehicleImageURL: vehicleImageURL || 'https://i.imgur.com/yy9vDEe.png',
    vehicle_type: vehicle_type || '',
    carGroup: carGroup || 'A',
    country: { code: '', name: {} },
    currencyCode: currencyCode || '',
    supplier: {
      code: supplier ? supplier.code : '',
      name: supplier ? supplier.name : '',
      image: supplier ? supplier.image : '',
    },
    supplierReservationData: {
      reservationNumber: supplierReservationData
        ? supplierReservationData.reservationNumber
        : '',
      accountNumber: supplierReservationData
        ? supplierReservationData.accountNumber
        : '',
      contractNumber: supplierReservationData
        ? supplierReservationData.contractNumber
        : '',
    },
    driverDetails: {
      title: driverDetails ? driverDetails.title : '',
      firstName: driverDetails ? driverDetails.firstName : '',
      lastName: driverDetails ? driverDetails.lastName : '',
      phone: driverDetails ? driverDetails.phone : '',
      age: driverDetails ? driverDetails.age : '',
      email: driverDetails ? driverDetails.email : '',
    },
    numberOfDays: numberOfDays ? numberOfDays : '',
    otherDetails: { numberOfDays: editOrder.numberOfDays || 0 },
    pickUpLocation: {
      LocationName: pickUpLocation ? pickUpLocation.LocationName : '',
      LocationCode: pickUpLocation ? pickUpLocation.LocationCode : '',
      Address: pickUpLocation ? pickUpLocation.Address : '',
      phone: pickUpLocation ? pickUpLocation.phone : '',
    },
    pickUpDate: pickUpDate ? new Date(pickUpDate).toISOString() : new Date(),
    pickUpTime: pickUpTime ? pickUpTime : timeSlots[0],
    dropOffLocation: {
      LocationName: dropOffLocation ? dropOffLocation.LocationName : '',
      LocationCode: dropOffLocation ? dropOffLocation.LocationCode : '',
      Address: dropOffLocation ? dropOffLocation.Address : '',
      phone: dropOffLocation ? dropOffLocation.phone : '',
    },
    dropOffDate: dropOffDate ? new Date(dropOffDate).toISOString() : new Date(),
    dropOffTime: dropOffTime || timeSlots[0],
    notIncluded: [],
    otherInformation: otherInformation || [],
    protections: protections || [],
    userExtras: userExtras || [],
    policies: [],
    voucher: {
      stamps: { headerStamps: [...headerStamps] },
    },
    additionalProducts: additionalProducts || [],
    createdBy: 'crm',
  });

  // useEffect(() => {
  //   console.log('order', order);
  // }, [order]);
  const [isLoading, setIsLoading] = useState({
    type: '', //saving/issuing voucher
    status: false,
  });

  const handleCarChange = (image, model) => {
    setOrder({ ...order, vehicleImageURL: image, vehicle_type: model });
  };
  const handleInputChange = (event) => {
    setOrder({
      ...order,
      driverDetails: {
        ...order.driverDetails,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleSave = async () => {
    setIsLoading({ type: 'saving', status: true });
    console.log(order);

    let response;
    try {
      response = await saveOrder(order);
      setInfoMessage({ open: true, severity: 'success' });
      console.log(response);
    } catch (err) {
      console.log(err.response.data);
      setInfoMessage({ open: true, severity: 'error' });
    }

    setIsLoading({ type: '', status: false });

    if (response?.data?.status || response?.data?.type === 'isSaved') {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    } else {
      console.log(response.message);
    }
  };
  const handleMessageSavedDialogClose = () => {
    setInfoMessage({ open: false, severity: 'success' });
  };
  // useEffect(() => {
  //   console.log('order is', order);
  // }, [order]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        fullScreen
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div style={{ display: 'flex' }}>
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  disabled={isLoading.status}
                  autoFocus
                  color="inherit"
                  onClick={handleSave}
                  style={{ marginRight: 8 }}
                >
                  save order
                </Button>
                {isLoading.type === 'saving' && isLoading.status && (
                  <StyledCircularProgress size={24} />
                )}
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  onClick={handleIssueVoucher}
                  variant="outlined"
                  autoFocus
                  color="inherit"
                  disabled={isLoading.status}
                >
                  issue voucher
                </Button>
                {isLoading.type === 'issuing' && isLoading.status && (
                  <StyledCircularProgress
                    size={24}
                    style={{ color: 'white' }}
                  />
                )}
              </ButtonWrapper>
            </div>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid
            container
            spacing={1}
            justify="space-between"
            style={{ padding: '16px', marginTop: '64px' }}
          >
            <Grid
              item
              md={4}
              style={{ padding: '16px', border: '1px solid #949494' }}
            >
              <LocationAndTime
                order={order}
                setOrder={setOrder}
                countries={countries}
                setCountries={setCountries}
              />
            </Grid>

            <Grid
              item
              md={4}
              style={{ padding: '16px', border: '1px solid #949494' }}
            >
              <DriverDetailsSection
                order={order}
                handleInputChange={handleInputChange}
              />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <ReservationDetails order={order} setOrder={setOrder} />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <CarDetails
                handleCarChange={handleCarChange}
                carImage={order.vehicleImageURL}
                carModel={order.vehicle_type}
                order={order}
                setOrder={setOrder}
                isEdit={isEditMode}
              />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <ExtrasAndInsurances order={order} setOrder={setOrder} />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <Policies order={order} setOrder={setOrder} />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <NotIncluded order={order} setOrder={setOrder} />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <OtherInformation order={order} setOrder={setOrder} />
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: 16, border: '1px solid #949494' }}
            >
              <VoucherRemarks setOrder={setOrder} order={order} />
            </Grid>
          </Grid>
        </DialogContent>
        <OrderSavedMessage
          severity={infoMessage.severity}
          open={infoMessage.open}
          handleMessageSavedDialogClose={handleMessageSavedDialogClose}
        />
      </Dialog>
    </LocalizationProvider>
  );
};

export default AddNewOrder;
