import { TextField, Typography } from '@mui/material';
import React from 'react';

const OtherInformation = ({ order, setOrder }) => {
  const handleChange = (e) => {
    // const old = order.otherInformation
    const values = e.target.value.split('\n');
    setOrder({ ...order, otherInformation: values });
  };

  return (
    <>
      <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 8 }}>
        Other information
      </Typography>
      <TextField
        value={order.otherInformation.join('\n')}
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        onChange={handleChange}
      />
    </>
  );
};

export default OtherInformation;
