import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const BlockTitle = styled(Typography)`
  padding: 8px;
`;

export const HeadBlock = styled(Grid)`
  border: 1px solid #d9c8c8;
  padding: 8px;
  border-radius: 2px;
  align-items: center;
`;
